import gsap, { fromTo, TweenMax } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import CSSPlugin from "gsap/CSSPlugin";
import CustomEase from "gsap/CustomEase";
// import playVideo from "./playFunctions";
// import pauseVideo from "./playFunctions";
// // import resetVideo from "./playFunctions";
// import pauseAudio from "./playFunctions";
// import playAudio from "./playFunctions";

gsap.registerPlugin(
  ScrollTrigger,
  CSSPlugin,
  CSSRulePlugin,
  CustomEase,
  TweenMax
);

let speed = 1000;
let speedSlow = 100;

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = screen.height * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

function initMobile() {
  var windowHeight = screen.height;
  var windowWidth = window.innerWidth;
  var frame = windowHeight;
  // var scrollElement = document.getElementById("scroll-element");

  // scrollElement.style.height = 10 * windowHeight + "px";

  window.addEventListener("resize", function () {
    ScrollTrigger.refresh();
    //   windowHeight = window.outerHeight;
    //   windowWidth = window.outerWidth;
    //   frame = windowHeight;

    //   scrollElement.style.height = 50 * windowHeight + "px";
  });

  const heroTitle = gsap.timeline();
  const astronautRotation = gsap.timeline();
  const astronautMovement = gsap.timeline();
  const storyTelling = gsap.timeline();

  ScrollTrigger.create({
    animation: heroTitle,
    trigger: "#animation-frame",
    start: "top top",
    end: () => frame * 4 + " center",
    scrub: 0.5,
    // markers: true
  });

  ScrollTrigger.create({
    animation: storyTelling,
    trigger: "#animation-frame",
    start: "top-=1 top",
    end: () => frame * 15 + " center",
    anticipatePin: 1,
    scrub: 1,
    ease: "power4.in",
    pin: "#animation-frame",
    // markers: true
  });

  ScrollTrigger.create({
    animation: astronautRotation,
    trigger: "#animation-frame",
    start: "top-=1 top",
    end: () => frame * 4.5 + " center",
    // anticipatePin: 1,
    scrub: 1,
    ease: "power4.in",
    // markers: true
  });

  ScrollTrigger.create({
    animation: astronautMovement,
    trigger: "#animation-frame",
    start: "top-=1 top",
    end: () => frame * 3 + " center",
    // anticipatePin: 1,
    scrub: 1,
    // ease: "power4.in",
    // markers: true
  });

  astronautRotation
    .fromTo(
      ".astronaut",
      {
        rotate: 76,
      },
      {
        rotate: 20,
      }
    )
    .fromTo(
      ".astronaut",
      {
        rotate: 20,
      },
      {
        rotate: -5,
      }
    );

  astronautMovement
    .fromTo(
      ".astronaut",
      {
        y: 60 + "%",
        x: 3 + "%",
        scale: 0.6,
      },
      {
        y: -100 + "%",
        x: 40 + "%",
        scale: 1,
        duration: 1
      },
      0
    )
    .fromTo(
      ".urhajos-green",
      { autoAlpha: 0 },
      { autoAlpha: 0.5, duration: 1, delay: 0.5 },
      0
    )
    .to(
      ".astronaut",
      {
        y: -2600,
        // rotate: 0,
        // stagger: 1,
        duration: 2,
        // delay: 0.6,
        ease: "power4.in",
      },
      1
    );

  storyTelling
    .set("#final-scene .form", { x: -50 + "%" }, 0)
    .to(".hero-title", { y: -18 * speedSlow }, 0)
    .to(
      ".bg-image-moon",
      { y: -3 * speedSlow, duration: 10 },
      0
    )
    .fromTo(
      "#hero-logo",
      {
        y: windowHeight + document.getElementById("hero-logo").offsetHeight,
      },
      {
        y: -50 + "%",
      },
      0
    )
    .fromTo(
      "#seat-belt",
      {
        y: windowHeight + document.getElementById("seat-belt").offsetHeight,
      },
      {
        y: 100 + "%",
      },
      0
    )
    .glow("#hero-logo .csillogas img", {}, 0)
    .to(
      "#seat-belt",
      {
        y: -8 * speedSlow,
        delay: 0.2,
      },
      1
    )
    .to(
      "#hero-logo",
      {
        y: -8 * speedSlow,
        delay: 0.18
      },
      1
    )
    .fromTo(
      "#video",
      {
        y: windowHeight + document.getElementById("video").offsetHeight,
      },
      {
        y: windowHeight / 2 - document.getElementById("video").offsetHeight / 2,
        delay: 0.5,
      },
      1
    )
    .to(
      "#video",
      {
        y: -windowHeight,
        delay: 0.5,
        duration: 2
      },
      2
    )
    // .fromTo("#final-scene", { autoAlpha: 0 }, { duration: 1, autoAlpha: 1 }, 4)
    .fromTo(
      "#final-scene .form",
      { y: windowHeight },
      {
        y: 0,
        delay: 1,
        // duration: 4,
      },
      2
    );
}

export default initMobile;
