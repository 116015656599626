import gsap, { fromTo, TweenMax, Linear } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CSSRulePlugin from "gsap/CSSRulePlugin";
import CSSPlugin from "gsap/CSSPlugin";
import CustomEase from "gsap/CustomEase";
// import playVideo from "./playFunctions";
// import pauseVideo from "./playFunctions";
// import pauseAudio from "./playFunctions";
// import playAudio from "./playFunctions";

gsap.registerPlugin(
  ScrollTrigger,
  CSSPlugin,
  CSSRulePlugin,
  CustomEase,
  TweenMax,
  Linear
);

const speed = 1000;
const speedSlow = 100;

gsap.registerEffect({
  name: "glow",
  effect: (targets, config) => {
    return gsap
      .timeline()
      .fromTo(
        targets,
        {
          scale: 0,
          rotate: 0,
        },
        {
          scale: 1.5,
          rotate: 45,
          ease: "power1.inOut",
          duration: config.duration,
          delay: config.delay,
        },
        1
      )
      .to(
        targets,
        {
          scale: 0,
          rotate: 90,
          duration: config.duration,
        },
        2
      );
  },
  defaults: { duration: 1, delay: 0 },
  extendTimeline: true,
});

function initDesktop() {
  var windowHeight = window.innerHeight;
  var windowWidth = window.innerWidth;
  var frame = windowHeight;
  // var scrollElement = document.getElementById("scroll-element");

  // scrollElement.style.height = 10 * windowHeight + "px";

  window.addEventListener("resize", function () {
    ScrollTrigger.refresh();
    windowHeight = window.innerHeight;
    windowWidth = window.innerWidth;
    frame = windowHeight;

    // scrollElement.style.height = 10 * windowHeight + "px";
  });

  const heroTitle = gsap.timeline();
  const astronautMovement = gsap.timeline();
  const astronautRotation = gsap.timeline();
  const storyTelling = gsap.timeline();

  ScrollTrigger.create({
    animation: heroTitle,
    trigger: "#animation-frame",
    start: "top top",
    end: () => frame * 1 + " center",
    scrub: 0.5,
    // markers: true
  });

  ScrollTrigger.create({
    animation: storyTelling,
    trigger: "#animation-frame",
    start: "top-=1 top",
    end: () => frame * 6 + " center",
    anticipatePin: 1,
    scrub: 1,
    ease: "power4.in",
    toggleActions: "play none none reverse",
    pin: "#animation-frame",
    // markers: true
  });

  // ScrollTrigger.create({
  //   animation: astronautMovement,
  //   trigger: "#scroll-element",
  //   start: "top-=1 top",
  //   end: () => frame * 3 + " center",
  //   anticipatePin: 1,
  //   scrub: 1.5,
  //   ease: "power4.inOut",
  //   // markers: true
  // });

  ScrollTrigger.create({
    animation: astronautRotation,
    trigger: "#animation-frame",
    start: "top-=1 top",
    end: () => frame * 1.5 + " center",
    // anticipatePin: 1,
    scrub: 3,
    // ease: "power4.in",
    // markers: true
  });
  ScrollTrigger.create({
    animation: astronautMovement,
    trigger: "#animation-frame",
    start: "top-=1 top",
    end: () => frame * 1.5 + " center",
    // anticipatePin: 1,
    scrub: 1,
    // ease: "power4.in",
    // markers: true
  });

  astronautMovement
    .fromTo(
      ".astronaut",
      {
        y: 60 + "%",
        x: 3 + "%",
        scale: 1,
      },
      {
        y: 20 + "%",
        x: 15 + "%",
        scale: 2,
        // duration: 2.3
      },
      0
    )
    .fromTo(
      ".urhajos-green",
      { autoAlpha: 0 },
      { autoAlpha: 0.5, duration: 1 },
      0
    )
    .to(
      ".astronaut",
      {
        y: -2600,
        // rotate: 0,
        // stagger: 1,
        duration: 2,
        // delay: 0.6,
        ease: "power4.in",
      },
      1
    );

  astronautRotation
    .fromTo(
      ".astronaut",
      {
        rotate: 76,
      },
      {
        rotate: 20,
      }
    )
    .fromTo(
      ".astronaut",
      {
        rotate: 20,
      },
      {
        rotate: 0,
      }
    )
   ;

  storyTelling
    .set("#final-scene .form", { x: -50 + "%" }, 0)
    .to(".hero-title", { y: -18 * speedSlow }, 0)
    .to(".animation-frame", { backgroundPositionY: speedSlow, duration: 10 }, 0)
    .fromTo(
      "#hero-logo",
      {
        y: windowHeight + document.getElementById("hero-logo").offsetHeight,
      },
      {
        y:
          windowHeight / 2 -
          document.getElementById("hero-logo").offsetHeight / 2,
        // duration: 2,
      },
      0
    )
    .fromTo(
      "#seat-belt",
      {
        y: windowHeight + document.getElementById("seat-belt").offsetHeight,
      },
      {
        y:
          windowHeight / 2 -
          document.getElementById("seat-belt").offsetHeight / 2,
      },
      0
    )
    .glow("#hero-logo .csillogas img", {}, 0)
    .to(
      "#seat-belt",
      {
        y: -2 * speedSlow,
        delay: 0.2,
      },
      1
    )
    .fromTo(
      "#video",
      {
        y: windowHeight + document.getElementById("video").offsetHeight,
      },
      {
        y: windowHeight / 2 - document.getElementById("video").offsetHeight / 2,
        delay: 0.4,
      },
      1
    )
    .fromTo(
      "#video",
      {
        scale: 1.5,
      },
      {
        scale: 1,
        delay: 0.4,
      },
      1
    )
    .to(
      "#hero-logo",
      {
        autoAlpha: 0,
        delay: 0.5,
      },
      2
    )
    .to(
      "#video",
      {
        y: -windowHeight,
        duration: 2
        // delay: 1,
      },
      3
    )
    .fromTo(
      "#video iframe",
      {
        scale: 1,
      },
      {
        scale: 0.5,
        duration: 2
      },
      3
    )
    .fromTo(
      "#final-scene .form",
      { y: windowHeight },
      {
        y: 0,
        delay: 1,
        duration: 1,
      },
      3
    );

}

export default initDesktop;
