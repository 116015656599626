// import $ from "jquery";
// import gsap, { fromTo } from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
// import CSSRulePlugin from "gsap/CSSRulePlugin";
// import CSSPlugin from "gsap/CSSPlugin";
// import CustomEase from "gsap/CustomEase";
import initDesktop from "./desktop";
import initMobile from "./mobile";

// gsap.registerPlugin(ScrollTrigger, CSSPlugin, CSSRulePlugin, CustomEase);


window.addEventListener("load", function () {
  resize();

  // window.addEventListener("resize", function () {
  //   resize();
  // });

  function resize() {
    if (window.matchMedia("(min-width: 768px)").matches) {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      
      initDesktop();
    } else {
      initMobile();
    }
  }

  const preload = document.querySelector(".preload");
  preload.classList.add("loaded");

  // document.getElementById("furo-hangja").addEventListener("click", function () {
  //   drillSound.play();
  // });
});
